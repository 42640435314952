import React from 'react'

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

export default ({ children }) => {
  return (
    <>
      {children}
    </>
  )
}